.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*{
  font-family:'Inter', sans-serif !important;
}

.ant-notification{
  z-index: 99999;
}
.headless-modal {
  position: fixed; /* Stay in place */
  z-index: 2147483647; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100vh; /*ios*/
  min-height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background-color: rgb(0, 0, 0);  */
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); 
  /* Black w/ opacity */
}

/* Modal Content */
.headless-modal-content {
  position: fixed;
  bottom: 0;
  top: 80px;
  /* background-color: #363535a1; */
  width: 100%;
}

.headless-modal-body {
  height: 600px;
  width: 50%;
  margin: 0 auto;
}
.headless-modal-body > iframe {
  height: 100%;
  border: 0;
  width: 100%;
}

.w-100 {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.f-bold {
  font-weight: bold;
}

.opacity-none {
  opacity: 1;
}

.opacity-full {
  opacity: 0;
}

.padding-top-8 {
  padding-top: 8px;
}

.text-decoration-unset {
  text-decoration: unset;
}

#otpbox > div > div:nth-child(6) > input[type=tel] {
  margin-right: 20px;
}

#otpbox > div > div > input {
  border-radius: 60px;
  border: 1px solid var(--UI-Component-Stroke-Medium, #8988BF) !important;
  background: var(--Surface-base, #FFF) !important;
}

#otpbox > div > div > input:focus,
#otpbox > div > div > input:focus-visible {
  border: 1px solid var(--UI-Component-Stroke-Medium, #8988BF) !important;  
  outline: none;
}

.overflow-hidden {
  overflow: hidden;
}

.text-center {
  text-align: center;
}

.m-auto {
  margin: auto;
}

.border-circle {
  border-radius: 50%;
}

.color-red {
  color: #e9000b !important;
}

.object-fit-contain {
  object-fit: contain;
}